import { useState } from 'react'
import useInterval from './useInterval'

export default function useTimeDifference(toTime) {
  const timeInterval = 1000 * 60 // 1 min
  function calculateTimeLeft() {
    const timeDifference =
      (new Date(toTime * 1000).getTime() - new Date().getTime()) / 1000
    return timeDifference
  }
  const [timeDifference, setDifference] = useState(calculateTimeLeft)

  function handleTimer() {
    setDifference(calculateTimeLeft())
  }
  useInterval(handleTimer, timeInterval) // run every sec
  return timeDifference || calculateTimeLeft()
}
