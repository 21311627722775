import React from "react";
import PropTypes from "prop-types";

import { parseAsText, parseAsContent } from "utils/parser";
import { getRedactorContent } from "utils/strings";

import RedactorText from "components/RedactorText";

export default function Redactor({ content, parseType, limit, ellipsis }) {
  switch (parseType) {
    case "text":
      return <RedactorText>{parseAsText(content)}</RedactorText>;

    case "textCollection":
      if (content === undefined) return content;
      if (!Array.isArray(content))
        return limit ? `${content.substring(0, limit)}${ellipsis}` : content;

      return (
        <RedactorText>
          {limit
            ? `${content
                .map((text) => getRedactorContent(text))
                .join("")
                .substring(0, limit)}${ellipsis}`
            : content.map((text) => getRedactorContent(text)).join("")}
        </RedactorText>
      );

    case "content":
      return parseAsContent(content);

    default:
      return null;
  }
}

Redactor.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  parseType: PropTypes.string,
  limit: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  ellipsis: PropTypes.string,
};

Redactor.defaultProps = {
  content: "",
  parseType: "content",
  limit: false,
  ellipsis: "...",
};
