import React from "react";
import PropTypes from "prop-types";

import { styled } from "utils/emotion";

const StyledTextVisual = styled("div")(
  {
    position: "absolute",
    top: 0,
    left: 0,
    userSelect: "none",
    color: "#00e2dc",
    fontWeight: "900",
    letterSpacing: "0.5vw",
    wordSpacing: "100vw",
    textTransform: "uppercase",
    lineHeight: "1",
    width: "90%",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      background: "#00e2dc",
      height: "0.3em",
    },
  },
  (props) => ({
    fontSize: props.fontSize,
    top: props.top,
    paddingTop: props.paddingTop, // see default props
    left: props.theme.gridSpaceGutter / 2,
    margin: props.margin,
    zIndex: props.zIndex || 0,
    "&::before": {
      width: props.width, // see default props
    },
  }),
  // visual text coloring depeding on default (dark bg) or alternate (white bg) ~DH
  (props) =>
    props.theme.name === "default"
      ? {
          opacity: 0.9,
          mixBlendMode: "overlay",
        }
      : {
          opacity: 0.4,
        }
);

export default function TextVisual({
  fontSize,
  width,
  margin,
  top,
  paddingTop,
  zIndex,
  children,
  className,
}) {
  return (
    <StyledTextVisual
      fontSize={fontSize}
      width={width}
      margin={margin}
      top={top}
      zIndex={zIndex}
      paddingTop={paddingTop}
      className={className}
    >
      {children}
    </StyledTextVisual>
  );
}

TextVisual.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  top: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  paddingTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array,
    PropTypes.string,
  ]),
  zIndex: PropTypes.string,
};

TextVisual.defaultProps = {
  fontSize: "10vw",
  width: "1.6em",
  paddingTop: "0.45em",
  margin: "auto",
  top: 0,
  zIndex: "0",
};
